export const CATEGORY_PILLS = 'Category Pills';
export const COMPONENT_TYPE_NAV = 'nav';
export const COMPONENT_TYPE_UI = 'ui';
export const CONTENTSTACK = 'ContentStack';
export const ENV_PROD = 'prod';
export const ENV_NONPROD = 'nonprod';
export const FILTER = 'filter';
export const FILTER_REMOVE = 'filterRemove';
export const FILTER_TYPE_LOCATION = 'Shop by Location';
export const FILTER_TYPE_PRICE = 'Shop by Price';
export const FILTER_TYPE_REVIEWS = 'Shop by Customer Reviews';
export const FILTER_VAL = 'filterVal';
export const FILTER_PICKUP = 'Pick Up';
export const KEY_SEARCH_INDEX = 'searchIndex';
export const DISPLAY_RANK = 'displayRank';
export const SESSION_STATUS_AUTHENTICATED = 'Authenticated';
export const SESSION_STATUS_UNAUTHENTICATED = 'Unauthenticated';
export const SEARCH = 'Search';
export const CATEGORY = 'Category';
export const SHOP_BY_PREFIX = 'Shop by ';
export const ERROR_PAGE = 'Error Page';
export const ERROR_PAGE_404 = 'Error Page | 404';
export const ERROR_PAGE_500 = 'Error Page | 500';
export const REACT = 'react';
export const TRACK_TYPE_MULTI_ATTR = 'multiAttribute';
export const TRACK_TYPE_NAV = 'navigation';
export const TRACK_TYPE_NAVIGATION = 'navigation';
export const TRAVEL_DESTINATION = 'travelDestination';
export const TRAVEL_ITEM_PID = '8888888';
