export const FACET_CS_DEV_KEY = 'search-facets';
export const BUTTON_SET_DEV_KEY = 'button_set';

export const DELIVERY_TEXT_KEY = 'facetlabel.shipit';
export const LIST_TITLE_KEY = 'filtertitle';
export const OUT_OF_STOCK_TEXT_KEY = 'facetlabel.outofstock';
export const PICK_UP_TEXT_KEY = 'facetlabel.pickup';
export const TWO_DAY_DELIVERY_TEXT_KEY = 'facetlabel.2daydelivery';
export const BUY_IN_WAREHOUSE_TEXT_KEY = 'facetlabel.inwarehouse';

export const COSTCOGROCERY_FACET_KEY = 'facetlabel.costcogrocery';
export const ITEM_CATEGORY_FACET_KEY = 'item_category';
export const ITEM_LOCATION_BOPIW = 'item_location_bopiw';
export const ITEM_LOCATION_FACET_KEY = 'item_location_availability';
export const ITEM_LOCATION_PRICING_FACET_KEY =
    'item_location_pricing_saleprice';
export const ITEM_RATING_FACET_KEY = 'item_rating_value';

export const DELIVERY_BUCKET_VALUE = 'shipit';
export const TWO_DAY_DELIVERY_BUCKET_VALUE = '2daydelivery';
export const OUT_OF_STOCK_BUCKET_VALUE = 'in stock';
export const IN_WAREHOUSE_BUCKET_VALUE = 'inwarehouse';

export const REFINE_QUERY_PARAM = 'refine';

export const A11Y_FILTER_KEY = 'a11y.searchfacetsreloadtext';
export const A11Y_FILTER_DESCRIBED_BY = 'a11y_facet_warning';
export const CURRENT_PAGE = 'currentPage';

export const FACET_OPTION_SELECTED_FILTER = 'facet_option_sf_';
export const SELECTED_FACETS = 'selectedFacets';
