'use client';

import {
    FusionProps,
    LocWHCookies,
    LocaleType,
} from '@/src/types/pages/search';

import {
    CATEGORY,
    CONTENTSTACK,
    ENV_NONPROD,
    ENV_PROD,
    ERROR_PAGE,
    ERROR_PAGE_404,
    ERROR_PAGE_500,
    REACT,
    SEARCH,
} from './constants';

const getPageType = (pageName: string) => {
    if ([ERROR_PAGE_404, ERROR_PAGE_500].includes(pageName)) {
        return ERROR_PAGE;
    } else if (pageName.includes(CATEGORY)) {
        return CATEGORY;
    } else {
        return SEARCH;
    }
};

/**
 * Function to populate the window.digitalData with the common search page properties
 * @param lang
 * @param locWHCookies
 * @param sessionStatus
 * @param site
 */
export function populateCommonSearchDigitalData(
    lang: LocaleType,
    locWHCookies: LocWHCookies,
    sessionStatus: 'Authenticated' | 'Unauthenticated' | undefined,
    site: string,
    pageName: string = SEARCH,
    pageCrumbs: (string | undefined)[] | undefined
) {
    const [siteLang, siteCountry] = lang.split('-');
    const siteStore = site.slice(-2).toLowerCase();
    const siteReleaseEnv =
        process?.env
            ?.NEXT_PUBLIC_CONTENT_ENTRY_ENVIRONMENT_CLIENT_CONFIGURATION &&
        process?.env?.NEXT_PUBLIC_CONTENT_ENTRY_ENVIRONMENT_CLIENT_CONFIGURATION.toLowerCase() ===
            ENV_PROD
            ? ENV_PROD
            : ENV_NONPROD;

    if (window) {
        window.digitalData = {
            ...window.digitalData,
            authStatus: sessionStatus,
            deliveryZip: locWHCookies.deliveryZip,
            localWH: locWHCookies.localWH,
            pageCMS: CONTENTSTACK,
            pageCrumbs,
            pageName,
            pagePlatform: REACT,
            pageType: getPageType(pageName),
            siteCountry,
            siteLang: `${siteLang}-${siteCountry?.toUpperCase()}`,
            siteReleaseEnv,
            siteStore,
        };
    }
}

/**
 * Function to populate the window.digitalData with the fusion properties from LW response
 * @param fusionProps
 * @param noResults
 */
export function populateFusionDigitalData(
    fusionProps: FusionProps,
    pageName: string | undefined
) {
    if (window) {
        window.digitalData = {
            ...window.digitalData,
            fusionQueryId: fusionProps.fusionQueryId,
            searchTerm: fusionProps.searchTerm,
            actualSearchTerm: fusionProps.actualSearchTerm,
            xFusionExitCode: fusionProps.xFusionExitCode,
            products: [],
            pageName,
        };
    }
}
