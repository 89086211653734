import {
    FILTER_TYPE_LOCATION,
    FILTER_TYPE_PRICE,
} from '@/components/Analytics/constants';
import { REFINEMENT_KEY_IN_STOCK } from '@/constants/index';
import { KeyValueToken } from '@/src/types/generics';

export function agnosticReplaceTokens(
    template: string | undefined,
    tokens: KeyValueToken[]
): string {
    // If the template is undefined, return an empty string.
    if (!template) {
        return '';
    }
    // If there are no tokens, save time and return the template as is.
    if (tokens.length === 0) {
        return template;
    }

    // Iterate over each token object
    tokens.forEach(token => {
        // For each key-value pair within the token object
        for (const key in token) {
            // Replace occurrences of the key with its corresponding value
            template = template!.replace(
                new RegExp(key, 'g'),
                token[key] as string
            );
        }
    });

    return template;
}

export function nonDefaultRefinementsFilter({
    filterType,
    refinement,
}: {
    filterType: string;
    refinement: string;
}): boolean {
    return (
        filterType !== FILTER_TYPE_LOCATION &&
        refinement !== REFINEMENT_KEY_IN_STOCK
    );
}
