'use client';

import React, { useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';

import { useSearchContext } from '@/src/services/search/SearchProvider';
import { SearchPageDigitalDataLayerProps } from '@/src/types/pages/search';
import { SearchResult } from '@/src/types/searchQuery';
import wcsUtils from '@/src/utils/WCS';
import { getLocalWarehouseFromCookies } from '@/src/utils/cookieUtils';
import { useQueryParams } from '@costcolabs/forge-digital-components';

import {
    populateCommonSearchDigitalData,
    populateFusionDigitalData,
} from './lib';

/**
 * Function to stringify the keyword if it is an array
 * @param keyword
 * @returns
 */
function stringifyKeyword(keyword: string | string[] | undefined) {
    return Array.isArray(keyword) ? keyword[0] : keyword;
}
/**
 * Function to populate fusion props for analytics
 * @param searchParams
 * @param searchResult
 * @returns
 */
function populateFusionAnalyticProps(
    keyword?: string,
    searchResult?: SearchResult
) {
    return {
        fusionQueryId: searchResult?.metrics?.queryId,
        searchTerm: searchResult?.metrics?.correction
            ? searchResult?.metrics?.correction
            : stringifyKeyword(keyword),
        actualSearchTerm: searchResult?.metrics?.original
            ? searchResult?.metrics?.original
            : stringifyKeyword(keyword),
        xFusionExitCode: searchResult?.metrics?.xFusionExitCode,
    };
}

const SearchPageDigitalDataLayer: React.FC<SearchPageDigitalDataLayerProps> = ({
    lang,
    fusionProps,
    pageCrumbs,
    pageName,
    productData,
    site,
}) => {
    const [cookies] = useCookies();

    useEffect(() => {
        const sessionStatus = wcsUtils.isWcsSessionEstablished()
            ? 'Authenticated'
            : 'Unauthenticated';

        if (site && lang) {
            populateCommonSearchDigitalData(
                lang,
                getLocalWarehouseFromCookies(cookies),
                sessionStatus,
                site,
                pageName,
                pageCrumbs
            );
        } else if (fusionProps) {
            populateFusionDigitalData(fusionProps, pageName);
        } else if (productData) {
            window?.digitalData?.products?.push(productData);
        }
    }, [site, lang, fusionProps, productData, cookies, pageCrumbs, pageName]);
    return null;
};

export const SearchPageDigitalDataLayerWithSearchResults = ({
    ...rest
}: SearchPageDigitalDataLayerProps) => {
    const { queryParams: searchParams } = useQueryParams();
    const { searchResult } = useSearchContext();
    const fusionProps = populateFusionAnalyticProps(
        searchParams.get('keyword') || '',
        searchResult
    );

    return <SearchPageDigitalDataLayer fusionProps={fusionProps} {...rest} />;
};

export default SearchPageDigitalDataLayer;
