import { CachePolicy, Query } from 'contentstack';
import { unstable_cache } from 'next/cache';

import { ContentType } from '@/constants/contentStack';
import type {
    ContentStackEntryDataProps,
    TextWithImages,
} from '@/types/contentStack';
import {
    ContentStack,
    ContentStackLivePreview,
} from '@costcolabs/forge-digital-components';
import type { LocaleProps } from '@costcolabs/forge-digital-components';

import { DEFAULT_FIELD_EXCEPTIONS } from './constants';

export type ContentTypeProps = (typeof ContentType)[keyof typeof ContentType];

/**
 *
 * @param contentType
 * @returns
 */
const _findByContentType = async function findByContentType<T>(
    contentType: ContentTypeProps,
    enableLivePreview?: boolean
): Promise<T> {
    let stackClient = ContentStack;

    if (enableLivePreview) {
        stackClient = ContentStackLivePreview;
        stackClient.setCachePolicy(CachePolicy.IGNORE_CACHE);
    }

    try {
        return (await stackClient.stack
            .ContentType(contentType)
            .Query()
            .toJSON()
            .findOne()) as T;
    } catch (err) {
        throw new Error(`An error ocurred in findByContentType: ${err}`);
    }
};
const cached_findByContentType = unstable_cache(
    _findByContentType,
    ['search_findByContentType'],
    {
        revalidate: 1800,
    }
);

export const findByContentType = async function findByContentType<T>(
    contentType: ContentTypeProps,
    enableLivePreview?: boolean
): Promise<T> {
    const fn =
        typeof window === 'undefined'
            ? cached_findByContentType
            : _findByContentType;

    return fn(contentType, enableLivePreview);
};

const _findByEntryId = async function findByEntryId<T>(
    lang: LocaleProps,
    contentType: ContentTypeProps,
    entryId: string,
    enableLivePreview?: boolean
): Promise<T> {
    let stackClient = ContentStack;

    if (enableLivePreview) {
        stackClient = ContentStackLivePreview;
        stackClient.setCachePolicy(CachePolicy.IGNORE_CACHE);
    }

    try {
        return (await stackClient.stack
            .ContentType(contentType)
            .Entry(entryId)
            .toJSON()
            .language(lang)
            .fetch()) as T;
    } catch (err) {
        console.log(err);
        throw new Error(
            `An error ocurred in findByEntryId for ${entryId}: ${JSON.stringify(err)}`
        );
    }
};
const cached_findByEntryId = unstable_cache(
    _findByEntryId,
    ['search_findByEntryId'],
    {
        revalidate: 1800,
    }
);

export const findByEntryId = async function findByEntryId<T>(
    lang: LocaleProps,
    contentType: ContentTypeProps,
    entryId: string,
    enableLivePreview?: boolean
): Promise<T> {
    const fn =
        typeof window === 'undefined' ? cached_findByEntryId : _findByEntryId;

    return fn(lang, contentType, entryId, enableLivePreview);
};

const _findOneWhere = async function _findOneWhere<T>(
    contentType: ContentTypeProps,
    key: string,
    value: string,
    enableLivePreview: boolean,
    lang: LocaleProps
): Promise<T> {
    let stackClient = ContentStack;

    if (enableLivePreview) {
        stackClient = ContentStackLivePreview;
        stackClient.setCachePolicy(CachePolicy.IGNORE_CACHE);
    }

    try {
        return (await stackClient.stack
            .ContentType(contentType)
            .Query()
            .language(lang)
            .where(key, value)
            .toJSON()
            .findOne()) as T;
    } catch (err) {
        throw new Error(
            `An error ocurred in findOneWhere: ${JSON.stringify(err)}`
        );
    }
};
const cached_findOneWhere = unstable_cache(
    _findOneWhere,
    ['search_findOneWhere'],
    {
        revalidate: 1800,
    }
);

export const findOneWhere = async function findOneWhere<T>(
    contentType: ContentTypeProps,
    key: string,
    value: string,
    enableLivePreview: boolean,
    lang: LocaleProps
): Promise<T> {
    const fn =
        typeof window === 'undefined' ? cached_findOneWhere : _findOneWhere;

    return fn(contentType, key, value, enableLivePreview, lang);
};

const _findAll = async function _findAll<T>(
    contentType: ContentTypeProps,
    enableLivePreview: boolean,
    lang: LocaleProps,
    limit?: number
): Promise<T> {
    let stackClient = ContentStack;

    if (enableLivePreview) {
        stackClient = ContentStackLivePreview;
        stackClient.setCachePolicy(CachePolicy.IGNORE_CACHE);
    }

    try {
        let query = stackClient.stack
            .ContentType(contentType)
            .Query()
            .language(lang)
            .toJSON();

        let result = await query.find();

        if (limit === 1) {
            return result?.[0]?.[0] as T;
        } else {
            return result as T;
        }
    } catch (err) {
        throw new Error(`An error ocurred in findAll: ${JSON.stringify(err)}`);
    }
};
const cached_findAll = unstable_cache(_findAll, ['search_findAll'], {
    revalidate: 1800,
});

export const findAll = async function findAll<T>(
    contentType: ContentTypeProps,
    enableLivePreview: boolean,
    lang: LocaleProps,
    limit?: number
): Promise<T> {
    const fn = typeof window === 'undefined' ? cached_findAll : _findAll;

    return fn(contentType, enableLivePreview, lang, limit);
};

const _findAllWhereRegex = async function _findAllWhereRegex<T>(
    contentType: ContentTypeProps,
    key: string,
    regex: string,
    options: string,
    enableLivePreview: boolean,
    lang: LocaleProps,
    limit?: number
): Promise<T> {
    let stackClient = ContentStack;

    if (enableLivePreview) {
        stackClient = ContentStackLivePreview;
        stackClient.setCachePolicy(CachePolicy.IGNORE_CACHE);
    }

    try {
        let query = stackClient.stack
            .ContentType(contentType)
            .Query()
            .language(lang)
            .regex(key, regex, options)
            .toJSON();

        if (limit) {
            query = query.limit(limit);
        }

        let result = await query.find();

        if (limit === 1) {
            return result?.[0]?.[0] as T;
        } else {
            return result as T;
        }
    } catch (err) {
        throw new Error(
            `An error ocurred in findAllWhere: ${JSON.stringify(err)}`
        );
    }
};
const cached_findAllWhereRegex = unstable_cache(
    _findAllWhereRegex,
    ['search_findAllWhere'],
    {
        revalidate: 1800,
    }
);

export const findAllWhereRegex = async function findAllWhere<T>(
    contentType: ContentTypeProps,
    key: string,
    regex: string,
    options: string,
    enableLivePreview: boolean,
    lang: LocaleProps,
    limit?: number
): Promise<T> {
    const fn =
        typeof window === 'undefined'
            ? cached_findAllWhereRegex
            : _findAllWhereRegex;

    return fn(contentType, key, regex, options, enableLivePreview, lang, limit);
};

const _findAllWhere = async function _findAllWhere<T>(
    contentType: ContentTypeProps,
    key: string,
    value: string,
    enableLivePreview: boolean,
    lang: LocaleProps,
    limit?: number
): Promise<T> {
    let stackClient = ContentStack;

    if (enableLivePreview) {
        stackClient = ContentStackLivePreview;
        stackClient.setCachePolicy(CachePolicy.IGNORE_CACHE);
    }

    try {
        let query = stackClient.stack
            .ContentType(contentType)
            .Query()
            .language(lang)
            .where(key, value)
            .toJSON();

        if (limit) {
            query = query.limit(limit);
        }

        let result = await query.find();

        if (limit === 1) {
            return result?.[0]?.[0] as T;
        } else {
            return result as T;
        }
    } catch (err) {
        throw new Error(
            `An error ocurred in findAllWhere: ${JSON.stringify(err)}`
        );
    }
};
const cached_findAllWhere = unstable_cache(
    _findAllWhere,
    ['search_findAllWhere'],
    {
        revalidate: 1800,
    }
);

export const findAllWhere = async function findAllWhere<T>(
    contentType: ContentTypeProps,
    key: string,
    value: string,
    enableLivePreview: boolean,
    lang: LocaleProps,
    limit?: number
): Promise<T> {
    const fn =
        typeof window === 'undefined' ? cached_findAllWhere : _findAllWhere;

    return fn(contentType, key, value, enableLivePreview, lang, limit);
};

const _findOneWithArrayValues = async function _findOneWithArrayValues<T>(
    contentType: ContentTypeProps,
    key: string,
    value: string[],
    enableLivePreview: boolean
): Promise<T> {
    let stackClient = ContentStack;

    if (enableLivePreview) {
        stackClient = ContentStackLivePreview;
        stackClient.setCachePolicy(CachePolicy.IGNORE_CACHE);
    }

    const andQuery = value.map(value => {
        // @ts-expect-error CS Typing does not include this valid syntax
        return { [key]: value.toLowerCase() } as Query;
    });
    try {
        return (await stackClient.stack
            .ContentType(contentType)
            .Query()
            .and(...andQuery)
            .toJSON()
            .findOne()) as T;
    } catch (err) {
        throw new Error(
            `An error ocurred in _findOneContainedIn (${contentType}, ${key}, ${JSON.stringify(andQuery)}): ${JSON.stringify(err)}`
        );
    }
};
const cached_findOneWithArrayValues = unstable_cache(
    _findOneWithArrayValues,
    ['search_findOneWithArrayValues'],
    { revalidate: 1800 }
);

export const findOneWithArrayValues = async function findOneWithArrayValues<T>(
    contentType: ContentTypeProps,
    key: string,
    value: string[],
    enableLivePreview: boolean
): Promise<T> {
    const fn =
        typeof window === 'undefined'
            ? cached_findOneWithArrayValues
            : _findOneWithArrayValues;

    return fn(contentType, key, value, enableLivePreview);
};

/**
 * Returns the entry for the given contentType and entryId.
 */
const _findOne = async function _findOne<T>(
    contentType: ContentTypeProps,
    entryId: string,
    fieldExceptions = DEFAULT_FIELD_EXCEPTIONS,
    enableLivePreview = false,
    lang: LocaleProps
): Promise<T> {
    let stackClient = ContentStack;

    if (enableLivePreview) {
        stackClient = ContentStackLivePreview;
        stackClient.setCachePolicy(CachePolicy.IGNORE_CACHE);
    }

    try {
        return (await stackClient.stack
            .ContentType(contentType)
            .Entry(entryId)
            .language(lang)
            .except(fieldExceptions)
            .toJSON()
            .fetch()) as T;
    } catch (err) {
        throw new Error(`An error ocurred in findOne: ${err}`);
    }
};
const cached_findOne = unstable_cache(_findOne, ['search_findOne'], {
    revalidate: 1800,
});

export const findOne = async function findOne<T>(
    contentType: ContentTypeProps,
    entryId: string,
    fieldExceptions = DEFAULT_FIELD_EXCEPTIONS,
    enableLivePreview = false,
    lang: LocaleProps
): Promise<T> {
    const fn = typeof window === 'undefined' ? cached_findOne : _findOne;

    return fn(contentType, entryId, fieldExceptions, enableLivePreview, lang);
};

const _getConfigurationSettingByDeveloperKey = async <T>(
    contentType: ContentTypeProps,
    developmentKey: string,
    locale: LocaleProps,
    enableLivePreview = false
): Promise<T | undefined> => {
    let stackClient = ContentStack;

    if (enableLivePreview) {
        stackClient = ContentStackLivePreview;
        stackClient.setCachePolicy(CachePolicy.IGNORE_CACHE);
    }

    try {
        const query = stackClient.stack
            .ContentType(contentType)
            .Query()
            .where('development_key', developmentKey)
            .language(locale)
            .toJSON()
            .findOne();
        const result = await query;
        return result;
    } catch (err) {
        console.error(err);
        throw new Error(
            `An error ocurred in getConfigurationSettingByDeveloperKey.
        contentType: ${contentType},
        developmentKey: ${developmentKey},
        locale: ${locale},
        enableLivePreview: ${enableLivePreview}
        ${JSON.stringify(err, null, 2)}`
        );
    }
};
const cached_getConfigurationSettingByDeveloperKey = unstable_cache(
    _getConfigurationSettingByDeveloperKey,
    ['search_getConfigurationSettingByDeveloperKey'],
    { revalidate: 1800 }
);

export const getConfigurationSettingByDeveloperKey = async <T>(
    contentType: ContentTypeProps,
    developmentKey: string,
    locale: LocaleProps,
    enableLivePreview = false
): Promise<T> => {
    const fn =
        typeof window === 'undefined'
            ? cached_getConfigurationSettingByDeveloperKey
            : _getConfigurationSettingByDeveloperKey;

    return fn(contentType, developmentKey, locale, enableLivePreview);
};

/**
 * Convenience method which directly returns the contents of the JSON property in a config entry
 */
export const _getConfigurationPropertiesByDeveloperKey = async <T>(
    contentType: ContentTypeProps,
    developmentKey: string,
    locale: LocaleProps,
    enableLivePreview = false
) => {
    const config: ContentStackEntryDataProps<T> =
        await getConfigurationSettingByDeveloperKey(
            contentType,
            developmentKey,
            locale,
            enableLivePreview
        );

    if (!config || !config.properties || !config.properties?.[0]) {
        return null;
    }

    return config.properties.shift();
};

const cached_getConfigurationPropertiesByDeveloperKey = unstable_cache(
    _getConfigurationPropertiesByDeveloperKey,
    ['search_getConfigurationPropertiesByDeveloperKey'],
    { revalidate: 1800 }
);

export const getConfigurationPropertiesByDeveloperKey = async (
    contentType: ContentTypeProps,
    developmentKey: string,
    locale: LocaleProps,
    enableLivePreview = false
) => {
    const fn =
        typeof window === 'undefined'
            ? cached_getConfigurationPropertiesByDeveloperKey
            : _getConfigurationPropertiesByDeveloperKey;

    return fn(contentType, developmentKey, locale, enableLivePreview);
};

const _getConfigurationByTitle = async <T>(
    contentType: ContentTypeProps,
    title: string,
    enableLivePreview = false
): Promise<T> => {
    let stackClient = ContentStack;

    if (enableLivePreview) {
        stackClient = ContentStackLivePreview;
        stackClient.setCachePolicy(CachePolicy.IGNORE_CACHE);
    }

    try {
        const query = stackClient.stack
            .ContentType(contentType)
            .Query()
            .where('title', title)
            .toJSON()
            .findOne();
        const result = await query;
        return result;
    } catch (err) {
        throw new Error(
            `An error occurred in function getConfigurationByTitle: ${err}`
        );
    }
};
const cached_getConfigurationByTitle = unstable_cache(
    _getConfigurationByTitle,
    ['search_getConfigurationByTitle'],
    { revalidate: 1800 }
);

export const getConfigurationByTitle = async <T>(
    contentType: ContentTypeProps,
    title: string,
    enableLivePreview = false
): Promise<T> => {
    const fn =
        typeof window === 'undefined'
            ? cached_getConfigurationByTitle
            : _getConfigurationByTitle;

    return fn(contentType, title, enableLivePreview);
};

export function getKeyValue(
    key: string,
    entry: ContentStackEntryDataProps
): string {
    return entry.key_value.find(item => item.key === key)?.value || '';
}

export const getImagesData = (
    composableWindow: ContentStackEntryDataProps['composable_window'],
    imageDevKey: string
) => {
    const composableWindowItem = composableWindow.find(
        item => item?.text_with_images?.development_key === imageDevKey
    );

    return composableWindowItem?.text_with_images;
};

export const getImageUrl = (key: string, imagesData?: TextWithImages) => {
    const imageInclusion = imagesData?.image_inclusion;
    const imageData = imageInclusion?.find(item => item?.image_key === key);
    return imageData?.image_picker[0]?.url;
};
