'use client';

/**
 * LucidWorks Search Service.
 * Each search service needs a URL constructor and a response parser.
 */
import type { WarehouseSearchParams } from '../types';
import {
    buildMultiSelectFacet,
    generateFacetFilterParam,
    getCurrentPage,
    getFirstItemFromPageNumber,
    getLWABValue,
    getNumberOfPages,
    isAttrRefinement,
    isPriceRefinement,
    isProgramRefinement,
    isRatingRefinement,
    isSearchPath,
} from '../utils';
import { generateEmptySearchResult } from '../utils';
import { isBDSite } from '../utils';
import type { ReadonlyURLSearchParams } from 'next/navigation';
import { isSea } from 'node:sea';

import {
    CATEGORY_PAGE_ID_REGEX,
    CATEGORY_QUERY_APP,
    LW_AB_QUERY_PARAM,
    PageTypes,
    SEARCH_QUERY_APP,
} from '@/constants/index';
import {
    REFINEMENT_KEY_DELIVERY,
    REFINE_QUERY_DELIMITER,
} from '@/constants/index';
import {
    fullyDecodeURI,
    modifyRangeString,
} from '@/src/components/SearchResultsFacets/utils';
import type {
    SearchPageConfigProperties,
    SearchServiceConfig,
} from '@/src/types/contentStack';
import { SearchResult } from '@/src/types/searchQuery';
import type { LocaleProps } from '@costcolabs/forge-digital-components';

/**
 * LucidWorks Search Service.
 * Each search service needs a URL constructor and a response parser.
 */

export function generateURL(
    searchParams: ReadonlyURLSearchParams,
    lang: LocaleProps,
    properties: SearchPageConfigProperties,
    { loc, whloc, userLocation, mdo }: WarehouseSearchParams,
    site: string,
    pageType: string
): string {
    let keyword = searchParams.get('keyword');

    let refine = fullyDecodeURI(searchParams.get('refine'));

    let currentPage = searchParams.get('currentPage') || 0;

    if (!properties) {
        return '';
    }

    const { baseUrl, resultsPerPage, searchApp, additionalURLParams } =
        properties;

    let facets = '';

    // "Delivery" filter is automatically applied unless opted out
    // for BC sites, BD sites do not have default facet params
    //deliveryFacetFlag=false item_program_eligibility-ShipIt
    if (!refine && !isBDSite(site)) {
        // If not explicitly opting out of delivery filter, apply it
        let deliveryFacetFlag = searchParams.get('deliveryFacetFlag');

        if (deliveryFacetFlag !== 'false') {
            refine = REFINEMENT_KEY_DELIVERY;
        }
    }

    if (refine) {
        const refinements = refine.split(REFINE_QUERY_DELIMITER);

        // Need to support multiple selections of price filters that are joined by OR
        const priceRefinements = refinements.filter(isPriceRefinement);

        if (priceRefinements.length > 0) {
            const priceRanges = priceRefinements.map(r => {
                let refinement = modifyRangeString(r);
                const [, value] = refinement.split(/-(.*)/s);
                return value;
            });

            // We need custom formatting so the results will still include all possible Price ranges
            // fq={!tag=item_location_pricing_salePrice}item_location_pricing_salePrice:([500 TO 1000])
            // Multiple ranges
            // fq={!tag=item_location_pricing_salePrice}item_location_pricing_salePrice:([25 TO 50] OR [100 TO 200])
            // facets += `&fq=${encodeURIComponent(`{!tag=item_location_pricing_salePrice}item_location_pricing_salePrice:(${value})`)}`;
            facets += `&fq=${encodeURIComponent(`{!tag=item_location_pricing_salePrice}item_location_pricing_salePrice:(${priceRanges.join(' OR ')})`)}`;
        }

        // Attribute refinements support multiselect, need all values to come back and apply multiple of the same filter
        const attrRefinements = refinements.filter(isAttrRefinement);

        if (attrRefinements.length) {
            facets += buildMultiSelectFacet(attrRefinements);
        }

        // Rating refinements support multiselect
        const ratingRefinements = refinements.filter(isRatingRefinement);

        if (ratingRefinements.length) {
            facets += buildMultiSelectFacet(ratingRefinements);
        }

        const programRefinements = refinements.filter(isProgramRefinement);
        if (programRefinements.length) {
            facets += buildMultiSelectFacet(programRefinements);
        }

        const otherRefinements = refinements.filter(
            r =>
                !isPriceRefinement(r) &&
                !isAttrRefinement(r) &&
                !isRatingRefinement(r) &&
                !isProgramRefinement(r)
        );

        for (const refinement of otherRefinements) {
            const [key, value] = refinement.split(/-(.*)/s);

            if (!key || !value) {
                continue;
            }

            facets += generateFacetFilterParam(refinement);
        }
    }

    const firstItem = getFirstItemFromPageNumber(
        parseInt(currentPage.toString(), 10),
        resultsPerPage
    );

    let locale = lang;

    // LW is case sensitive, but all our langs are lowercase
    if (locale.includes('-')) {
        const langParts = lang.split('-');
        if (langParts.length === 2) {
            locale =
                `${langParts[0]!}-${langParts[1]!.toUpperCase()}` as LocaleProps;
        }
    }
    const sort = fullyDecodeURI(searchParams.get('sortBy'));

    const params = new URLSearchParams({
        [LW_AB_QUERY_PARAM]: getLWABValue(),
        q: keyword || '*:*',
        locale,
        start: firstItem.toString(),
        expand: 'false',
    });

    const optionalParams: { [key: string]: string | null | undefined } = {
        userLocation,
        loc,
        whloc,
        mdo,
    };

    for (const param in optionalParams) {
        if (optionalParams[param]) {
            params.set(param, `${optionalParams[param]}`);
        }
    }

    // Special case for loc not being set, default to star
    if (!loc) {
        params.set('loc', '*');
    }

    // NOTE: THIS IS VERY IMPORTANT WHILE AKAMAI IS ROUTING /cateogry.html to /c/category.html only internally.
    // The only way to tell its a category page is if it was a category on the server (pageType), or its not the searchPage in the url (exactly === /s)
    const isCategoryPage =
        pageType === PageTypes.CATEGORY ||
        !isSearchPath(window.location.pathname);

    const basePath = `/${searchApp}/query/${searchApp}_${isCategoryPage ? CATEGORY_QUERY_APP : SEARCH_QUERY_APP}`;

    if (isCategoryPage) {
        const [_match, pageId] =
            CATEGORY_PAGE_ID_REGEX.exec(window.location.pathname) || [];

        params.set('url', `/${pageId}.html`);
    }

    let url = `${baseUrl}${basePath}?${params.toString()}${facets}`;

    // URL encoding appears to break the sort on the LW side, so add it raw
    if (sort) {
        url += `&sort=${sort}`;
    }

    if (additionalURLParams) {
        url += `&${additionalURLParams}`;
    }

    return url;
}

export async function LucidWorksQuery(
    searchParams: ReadonlyURLSearchParams,
    lang: LocaleProps,
    requestHeaders: SearchServiceConfig['required_request_headers'],
    properties: SearchPageConfigProperties,
    { loc, whloc, userLocation, mdo }: WarehouseSearchParams,
    pageType: string,
    site: string
): Promise<SearchResult> {
    let returnValue: SearchResult = generateEmptySearchResult();

    if (!properties) {
        return returnValue;
    }
    const { revalidateInMs, resultsPerPage } = properties;

    if (!searchParams.get('keyword') && pageType === PageTypes.SEARCH) {
        return returnValue;
    }

    const requestURL = generateURL(
        searchParams,
        lang,
        properties,
        {
            loc,
            whloc,
            userLocation,
            mdo,
        },
        site,
        pageType
    );

    const response = await fetch(requestURL, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...requestHeaders,
        },
        next: {
            revalidate: revalidateInMs,
        },
    });

    if (!response.ok) {
        console.error('LW FETCH ERROR', response.statusText);
        return returnValue;
    }

    let data = await response.json();

    returnValue.pagination.currentPage = getCurrentPage(
        data.response.start,
        resultsPerPage
    );
    returnValue.pagination.totalDocs = data.response.numFound;
    returnValue.pagination.totalPages = getNumberOfPages(
        data.response.numFound,
        resultsPerPage
    );
    returnValue.docs = data.response.docs;
    returnValue.variants = data.expanded;
    returnValue.facets = data.facets ? Object.values(data.facets) : [];

    returnValue.isAdTargetingExeptionEnabled =
        data.fusion['X-Fusion-FSACHDI-Exception'] === '1';
    returnValue.isAdTargetingExplicitlyDisabled =
        data.fusion['X-Fusion-IncludesFSACHDI'] === '1';
    returnValue.isAdTargetingEnabled =
        returnValue.isAdTargetingExeptionEnabled ||
        !returnValue.isAdTargetingExplicitlyDisabled;

    returnValue.metrics.original = data.fusion['search.spelling.original'];
    returnValue.metrics.correction = data.fusion['search.spelling.corrected'];
    returnValue.metrics.queryTime = data.responseHeader.QTime;
    returnValue.metrics.queryId = data.fusion.fusionQueryId;
    returnValue.metrics.totalTime = data.responseHeader.totalTime;
    returnValue.metrics.semantic = !!data.fusion['search.related_results'];

    returnValue.breadcrumb = data?.fusion?.breadcrumb;

    if (data?.fusion?.redirect) {
        returnValue.redirect = data.fusion.redirect[0];
    }

    returnValue.defaultFusionSort = data?.fusion?.requestedSort;

    return returnValue;
}
