const CATEGORY_LINK_PREFIX = '/c/';
const SEARCH_PAGE_PREFIX = '/s?';

export function modernizeCategoryLink(
    categoryPath: string | undefined,
    link: string
) {
    // If bad URLs, just default to home
    if (!link || link === CATEGORY_LINK_PREFIX) {
        return '/';
    }

    // Some category URLs are actually to the search page
    if (link.startsWith(SEARCH_PAGE_PREFIX)) {
        return link;
    }

    // If it already as the modern format, return it as is
    if (link.startsWith(CATEGORY_LINK_PREFIX)) {
        return link;
    }

    if (!categoryPath) {
        return link;
    }

    // Make sure we don't duplicate the preceding slash
    if (link.startsWith('/')) {
        return `${categoryPath}${link.slice(1)}`;
    }

    return `${categoryPath}${link}`;
}
