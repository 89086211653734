'use client';

import { ReadonlyURLSearchParams } from 'next/navigation';

import React, {
    ReactNode,
    createContext,
    memo,
    useContext,
    useEffect,
    useState,
} from 'react';

import type {
    ContentStackEntryDataProps,
    SearchPageConfigProperties,
    SearchServiceConfig,
} from '@/src/types/contentStack';
import { SearchParams, SearchResult } from '@/src/types/searchQuery';
import { PageType } from '@/types/pages/search';
import type { LocaleProps } from '@costcolabs/forge-digital-components';
import {
    useBrowseContext,
    useQueryParams,
} from '@costcolabs/forge-digital-components';

import { SearchServiceProviders } from './constants';
import { LucidWorksQuery } from './services/LucidWorks';
import type { SearchContextType, WarehouseCookieValue } from './types';
import {
    checkForItemNumberRedirect,
    getLocationsFromCookie,
    isBDSite,
    updatePageTitle,
} from './utils';

// Create the context with a default value
const SearchContext = createContext<SearchContextType | undefined>(undefined);

type SearchProviderProps = {
    lang: LocaleProps;
    config: ContentStackEntryDataProps;
    serviceConfig: SearchPageConfigProperties;
    children: ReactNode;
    requestHeaders: SearchServiceConfig['required_request_headers'];
    NoResultsComponent: ReactNode;
    loc?: string;
    whloc?: string;
    userLocation?: string;
    pageType: PageType;
    isEnabled?: boolean;
    enableLivePreview?: boolean;
    livePreviewKeyword?: string;
    mdo?: string;
    site: string;
};

const SearchProviderComponent = ({
    lang,
    serviceConfig,
    requestHeaders,
    children,
    NoResultsComponent,
    loc,
    whloc,
    userLocation,
    mdo,
    pageType,
    isEnabled = true,
    enableLivePreview,
    livePreviewKeyword,
    site,
}: SearchProviderProps) => {
    const { queryParams: searchParams } = useQueryParams();
    const [searchResult, setSearchResult] = useState<
        SearchResult | undefined
    >();
    const [searchedParams, setSearchedParams] = useState<
        SearchParams | undefined
    >();
    const [isSearchResultsLoading, setIsSearchResultsLoading] =
        useState<boolean>(true);

    // TODO: This should come from ContentStack, cookie, etc.
    const [searchService, _setSearchService] = useState<string>(
        SearchServiceProviders.LucidWorks
    );

    useEffect(() => {
        const run = async () => {
            setIsSearchResultsLoading(true);
            try {
                let effectiveSearchParams = searchParams;

                if (enableLivePreview && livePreviewKeyword) {
                    const paramsObject = searchParams
                        ? Object.fromEntries(searchParams.entries())
                        : {};
                    paramsObject['keyword'] = livePreviewKeyword;
                    effectiveSearchParams = new URLSearchParams(
                        paramsObject
                    ) as ReadonlyURLSearchParams;
                }

                let searchResult: SearchResult | undefined;
                switch (searchService) {
                    case SearchServiceProviders.LucidWorks:
                        searchResult = await LucidWorksQuery(
                            effectiveSearchParams,
                            lang,
                            requestHeaders,
                            serviceConfig,
                            { loc, whloc, userLocation, mdo },
                            pageType,
                            site
                        );
                        break;
                    default:
                        throw new Error(
                            `Search service "${searchService}" not supported`
                        );
                }

                const kw = effectiveSearchParams.get('keyword')!;

                const redirect = checkForItemNumberRedirect(kw, searchResult);

                if (redirect) {
                    window.location.href = redirect;
                    return;
                }

                if (searchResult?.redirect) {
                    /* Bug 114623: in case of category page redirect, onclick of back button was not navigating user to page it came from */
                    // replace method of location is used so that it doesn't save the current request in the browser history
                    window.location.replace(searchResult.redirect);

                    return;
                }

                updatePageTitle(effectiveSearchParams, searchResult, kw);

                setSearchResult(searchResult);
                setSearchedParams(
                    Object.fromEntries(effectiveSearchParams.entries())
                );
                setIsSearchResultsLoading(false);
            } catch (err) {
                console.error(err);
                setIsSearchResultsLoading(false);
            }
        };
        if (loc && userLocation) {
            run();
        }
    }, [
        enableLivePreview,
        pageType,
        lang,
        livePreviewKeyword,
        loc,
        requestHeaders,
        searchParams,
        searchService,
        serviceConfig,
        userLocation,
        whloc,
        site,
    ]);

    // Search is actively enabled, or a refinement tag is present on a search disabled page
    const isSearchActive = isEnabled || !!searchParams.get('refine');

    const isNoResultsComponentUsed =
        isSearchActive &&
        !isSearchResultsLoading &&
        (!searchResult || searchResult.docs?.length === 0);

    return (
        <SearchContext.Provider
            value={{ searchResult, isSearchResultsLoading, searchedParams }}
        >
            {isNoResultsComponentUsed ? NoResultsComponent : children}
            <div
                dangerouslySetInnerHTML={{
                    __html: `<!-- 
                        resultsLeadToFSACHDI: ${searchResult?.isAdTargetingExplicitlyDisabled ? 1 : 0}
                        isFSACHDIExceptionSet: ${searchResult?.isAdTargetingExeptionEnabled ? 1 : 0}
                        doNotTrackLayout: ${!searchResult?.isAdTargetingEnabled}
                    -->`,
                }}
            />
        </SearchContext.Provider>
    );
};

const MemoizedSearchProviderComponent = memo(SearchProviderComponent);

// Create the provider component
export const SearchProvider = (props: SearchProviderProps) => {
    const { warehouse, deliveryLocation, bdWarehouseNumber } =
        useBrowseContext();

    let { loc, whloc, mdo } = getLocationsFromCookie(
        warehouse as WarehouseCookieValue
    );
    const { state } = deliveryLocation || {};

    // BD does not use the whloc param, and uses the BD warehouse cookie for loc instead
    if (isBDSite(props.site)) {
        whloc = '';
        loc =
            bdWarehouseNumber?.value || props.serviceConfig.defaultBDWarehouse;
    }

    return (
        <MemoizedSearchProviderComponent
            loc={loc}
            whloc={whloc}
            userLocation={state}
            mdo={mdo}
            {...props}
        />
    );
};

// Create a custom hook to use the SearchContext
export const useSearchContext = (): SearchContextType => {
    const context = useContext(SearchContext);
    if (context === undefined) {
        throw new Error(
            'useSearchContext must be used within a SearchProvider'
        );
    }
    return context;
};
