import { PageType } from '@/types/pages/search';

export const CONTENT_STACK_DEV_KEY = 'search-page';
export const ITEM_CATEGORY_FACET_KEY = 'item_category';
export const ITEM_LOCATION_BOPIW = 'item_location_bopiw';
export const ITEM_LOCATION_BOPIM_KEY = 'item_location_bopim';
export const ITEM_LOCATION_FACET_KEY = 'item_location_availability';
export const ITEM_LOCATION_PRICING_FACET_KEY =
    'item_location_pricing_saleprice';
export const ITEM_PROGRAM_FACET_KEY = 'item_program_eligibility';
export const ITEM_RATING_FACET_KEY = 'item_rating_value';

// Refinement keys are facetKey-value
export const REFINEMENT_KEY_DELIVERY = 'item_program_eligibility-ShipIt';
export const REFINEMENT_KEY_IN_STOCK = 'item_location_availability-in stock';

export const PUBLIC_DIRECTORY = '/nextSearchAssets';
export const WAREHOUSE_DELIVERY_COOKIE_NAME = 'WAREHOUSEDELIVERY_WHS';

// Filter labels
export const DELIVERY_TEXT_KEY = 'facetlabel.shipit';
export const LIST_TITLE_KEY = 'filtertitle';
export const OUT_OF_STOCK_TEXT_KEY = 'facetlabel.outofstock';
export const PICK_UP_TEXT_KEY = 'facetlabel.pickup';
export const BOPIM_PICK_UP_TEXT_KEY = 'facetlabel.pickup.bopim';
export const TWO_DAY_DELIVERY_TEXT_KEY = 'facetlabel.2daydelivery';
export const BUY_IN_WAREHOUSE_TEXT_KEY = 'facetlabel.inwarehouse';

export const IN_WAREHOUSE_BUCKET_VALUE = 'inwarehouse';

export const REFINE_QUERY_DELIMITER = '||';

// LucidWorks A/B Support
export const LW_AB_STORAGE_NAME = 'adobeopt_exp';
export const LW_AB_QUERY_PARAM = 'expoption';
export const LW_AB_DEFAULT_VALUE = 'def';

// Cookies
export const INVENTORY_POSTAL_CODE = 'invCheckPostalCode';
export const C_LOCATION = 'C_LOC';
export const EN_US_LANG_ID = '-1';
export const EN_CA_LANG_ID = '-24';
export const FR_CA_LANG_ID = '-25';

// Language/Locale codes
export const EN_US_LOCALE = 'en-us';
export const EN_CA_LOCALE = 'en-ca';
export const FR_CA_LOCALE = 'fr-ca';

// Breadcrumbs
export const SKIP_TO_RESULTS_TEXT_KEY = 'skiptoresults';
export const BREADCRUMB_ARIA_LABEL_KEY = 'arialabelbreadcrumb';
export const SEARCH_RESULT_CONTAINER_ID = 'productList';

export const CATEGORY_PATH_PREFIX = '/c/';
export const SEARCH_PATH_PREFIX = '/s';
export const CATEGORY_QUERY_APP = 'navigation';
export const SEARCH_QUERY_APP = 'search';

// Captures the * from *.html and /c/*.html
export const CATEGORY_PAGE_ID_REGEX = /(?:\/c\/)?([^/]+)\./i;

export const PageTypes: { [key: string]: PageType } = {
    CATEGORY: 'category',
    SEARCH: 'search',
};
